<template>
    <b-container>
        <div style="margin:5px;">
            <b-row>
                <div class="card" style="margin:5px 20px;width:100%">
                    <div class="card-body">
                        <b-form>
                            <b-form-group id="groupName" label="" label-for="search">
                                <b-form-input id="search"
                                    v-model="form.search"
                                    placeholder="请输入客户姓名/昵称/手机号码">
                                </b-form-input>
                            </b-form-group>
                            <div style="text-align:right">
                                <b-button type="button" @click="search" variant="primary" style="margin-right:5px;">
                                    查询
                                </b-button>
                                <b-button type="button" @click="onReset" variant="secondary" style="margin:0px 5px;">
                                    重置
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </b-row>
        </div>
        <div v-if="customers.length > 0" 
            style="margin:5px auto;width:90%;">
            <b-row style="justify-content:space-around;">
                <b-table :items="customers" :fields="fields" 
                    small responsive hover selectable select-mode="single" 
                    @row-selected="onRowSelected"
                    ref="customerTable">
                    <template #cell(avatar_url)="data">
                        <b-img thumbnail fluid :src="data.item.avatar_url" 
                            center lazy width="30" height="30"
                            v-if="data.item.avatar_url!=''">
                        </b-img>
                    </template>
                    <template #cell(gender)="data">
                        {{getGender(data.item.gender)}}
                    </template>
                    <template #cell(create_time)="data">
                        {{dateFormat(data.item.create_time)}}
                    </template>
                    <template #cell(last_login_time)="data">
                        {{dateFormat(data.item.last_login_time)}}
                    </template>
                </b-table>
            </b-row>
            <div style="margin-top:10px;width:90%;">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="pageSize"
                    align="center">
                </b-pagination>
                <div style="height:30px;line-height:30px;text-align:center;">
                    第 {{ currentPage }} 页
                    总计: {{ total }} 行
                </div>
            </div>
        </div>
        <div v-else style="margin:10px;">
            <h6>没有客户</h6>
        </div>

        <b-modal id="customer-info" title="客户信息" size="xl"
            hide-footer
            v-model="modalShow">
            <div class="card" style="margin:5px 10px;width:95%;"
                v-if="selectedCustomer != null">
                <div class="card-header">
                    <div>
                        <b-img thumbnail fluid :src="selectedCustomer.avatar_url" 
                            center lazy width="50" height="50" rounded="circle"
                            v-if="selectedCustomer.avatar_url != ''">
                        </b-img>
                    </div>
                </div>
                <div class="card-body body">
                    <div class="item">
                        <div v-if="selectedCustomer.name != null" style="width:30%;">姓名:</div>
                        <div style="width:70%">{{selectedCustomer.name}}</div>
                    </div>
                    <div class="item">
                        <div style="width:30%;">昵称:</div>
                        <div style="width:70%">{{selectedCustomer.nick_name}}</div>
                    </div>
                    <div v-if="selectedCustomer.customer_tel != null" class="item">
                        <div style="width:30%;">手机号码:</div>
                        <div style="width:70%">{{selectedCustomer.customer_tel}}</div>
                    </div>
                    <div class="item">
                        <div style="width:30%;">性别:</div>
                        <div style="width:70%">{{getGender(selectedCustomer.gender)}}</div>
                    </div>
                    <div class="item">
                        <div style="width:30%;">所在地:</div>
                        <div style="width:70%">{{selectedCustomer.province}} {{selectedCustomer.city}}</div>
                    </div>
                    <div class="item">
                        <div style="width:30%;">初次访问时间:</div>
                        <div style="width:70%">{{dateFormat(selectedCustomer.create_time)}}</div>
                    </div>
                    <div class="item">
                        <div style="width:30%;">最后访问时间:</div>
                        <div style="width:70%">{{dateFormat(selectedCustomer.last_login_time)}}</div>
                    </div>
                </div>
            </div>
            <div class="feedback-header">
                <div style="width:20%">客户反馈</div>
            </div>
            <div v-if="feedbacks != null && feedbacks.length > 0" style="margin-top:10px;">
                <b-table :items="feedbacks" :fields="feedbackFields" 
                    small responsive hover selectable select-mode="single"
                    @row-selected="onFeedbackRowSelected">
                    <template #cell(category)="data">
                        {{getCategory(data.item.category)}}
                    </template>
                    <template #cell(is_replied)="data">
                        <span :style="{color: getStatusColor(data.item.is_replied)}">
                            {{data.item.is_replied == true ? "已回复" : "未回复"}}
                        </span>
                    </template>
                    <template #cell(create_time)="data">
                        {{dateFormat(data.item.create_time)}}
                    </template>
                    <template #cell(reply_time)="data">
                        {{data.item.is_replied == true ? dateFormat(data.item.reply_time) : ''}}
                    </template>
                </b-table>
            </div>
            <div v-else style="margin:10px;">
                <h6>没有反馈</h6>
            </div>
            <div v-if="selectedFeedback != null" style="margin-top:10px;margin-bottom:10px;">
                <div class="feedback-item">
                    反馈时间: {{dateFormat(selectedFeedback.create_time)}} 
                    <img :src="`${publicPath}arrow-up.png`" alt="" style="height:28px;width:20px;cursor:pointer;margin-left:5px;"
                        v-if="selectedFeedback != null" 
                        @click="selectedFeedback = null">
                </div>
                <div class="feedback-item"
                    v-if="selectedFeedback.is_replied == true">
                    回复时间: {{dateFormat(selectedFeedback.reply_time)}}
                </div>
                <div class="feedback-item">
                    反馈图片:
                </div>
                <div v-if="feedbackPictures.length > 0" class="feedback-image-box">
                    <div v-for="picture in feedbackPictures" :key="picture" class="feedback-image-item">
                        <b-img :src="picture" thumbnail style="width:98%;height:auto;"/>
                    </div>
                </div>
                <div class="feedback-item">
                    反馈内容:
                </div>
                <b-form-textarea
                    id="feedback-content"
                    v-model="selectedFeedback.content"
                    rows="6"
                    max-rows="12"
                    disabled>
                </b-form-textarea>
            </div>
            <div class="like-header">
                客户收藏
            </div>
            <div v-if="likes != null && likes.length > 0" 
                class="likes">
                <div v-for="like in likes" 
                    v-bind:key="like.product_code" 
                    class="like-item">
                    <b-img :src="like.picture_url" thumbnail class="like-item-image"/>
                    <div border class="like-item-info">
                        <div class="like-item-value">
                            {{ like.product_code }} 
                        </div>
                        <div class="like-item-value like-item-name">
                            {{ like.product_name }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="margin:10px;">
                <h6>没有收藏</h6>
            </div>
            <div class="like-header">
                客户收货地址
            </div>
            <div v-if="addresses != null && addresses.length > 0" style="margin-top:10px;">
                <b-table :items="addresses" :fields="addressFields" 
                    small responsive hover selectable select-mode="single"
                    :tbody-tr-class="rowClass">
                    <template #cell(is_deleted)="data">
                        <span :style="{color: getIsDeletedColor(data.item.is_deleted)}">
                            {{data.item.is_deleted == true ? "已删除" : "使用中"}}
                        </span>
                    </template>
                </b-table>
            </div>
            <div v-else style="margin:10px;">
                <h6>没有收货地址</h6>
            </div>
            <div class="login-header">
                客户登录历史
            </div>
            <div v-if="loginLogs != null && loginLogs.length > 0" style="margin-top:10px;">
                <div style="margin-bottom:10px;width:90%;text-align:left;">
                    <b-link href="#" @click="clearLoginHistory()"
                        style="font-size:13px;">
                        清空登录历史
                    </b-link>
                </div>
                <b-table :items="loginLogs" :fields="loginLogFields" 
                    small responsive hover selectable select-mode="single">
                    <template #cell(avatar_url)="data">
                        <b-img thumbnail fluid :src="data.item.avatar_url" 
                            center lazy width="30" height="30"
                            v-if="data.item.avatar_url!=''">
                        </b-img>
                    </template>
                    <!-- <template #cell(last_login_time)="data">
                        {{dateFormat(data.item.last_login_time)}}
                    </template> -->
                    <template #cell(login_time)="data">
                        {{dateFormat(data.item.login_time)}}
                    </template>
                </b-table>
                <div style="margin-top:10px;width:90%;">
                    <b-pagination
                        v-model="currentLogPage"
                        :total-rows="totalLog"
                        :per-page="logPageSize"
                        align="center">
                    </b-pagination>
                    <div style="height:30px;line-height:30px;text-align:center;">
                        第 {{ currentLogPage }} 页
                        总计: {{ totalLog }} 行
                    </div>
                </div>
            </div>
            <div v-else style="margin:10px;">
                <h6>没有登录历史</h6>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import { 
    Indicator, 
    MessageBox
} from 'mint-ui';

import { 
    getCustomerList
} from "api/customer";

import {
    getCustomerAddressList
} from "api/address";

import {
    getFeedbackList, replyFeedback
} from "api/feedback";

import {
    getProductListByProductCodes
} from "api/product";

import { 
    getPicturePath 
} from "api/picture";

import { 
    getLoginLogList, deleteLoginLogList
} from "api/loginlog";

import { 
    getFeedbackPicturePath 
} from "api/picture";

export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
            isMobile: false,
            loading: true,
            disabled: true,
            customers: [],
            form: {
                search: null
            },
            fields: [],
            fieldsSimple: [
                {
                    key: 'avatar_url',
                    label: '头像'
                },
                {
                    key: 'name',
                    label: '姓名'
                },
                {
                    key: 'nick_name',
                    label: '昵称'
                },
                {
                    key: 'tel',
                    label: '手机'
                }
            ],
            fieldsAll: [
                {
                    key: 'avatar_url',
                    label: '头像'
                },
                {
                    key: 'name',
                    label: '姓名'
                },
                {
                    key: 'nick_name',
                    label: '昵称'
                },
                {
                    key: 'gender',
                    label: '性别'
                },
                {
                    key: 'province',
                    label: '省份'
                },
                {
                    key: 'city',
                    label: '城市'
                },
                {
                    key: 'tel',
                    label: '手机号码'
                },
                {
                    key: 'create_time',
                    label: '创建时间'
                },
                {
                    key: 'last_login_time',
                    label: '最后登录时间'
                }
            ],
            showAll: false,
            feedbackFields: [],
            feedbackFieldsSimple: [
                {
                    key: 'is_replied',
                    label: '状态',
                    sortable: true 
                },
                {
                    key: 'category',
                    label: '类别'
                },
                {
                    key: 'tel',
                    label: '手机号码'
                }
            ],
            feedbackFieldsAll: [
                {
                    key: 'is_replied',
                    label: '状态',
                    sortable: true 
                },
                {
                    key: 'category',
                    label: '类别'
                },
                {
                    key: 'tel',
                    label: '手机号码'
                },
                {
                    key: 'create_time',
                    label: '反馈时间',
                    sortable: true 
                },
                {
                    key: 'reply_time',
                    label: '回复时间',
                    sortable: true 
                }
            ],
            addressFields: [],
            addressFieldsSimple: [
                {
                    key: 'address_name',
                    label: '名称'
                },
                {
                    key: 'user_name',
                    label: '收件人'
                },
                {
                    key: 'tel_number',
                    label: '电话号码'
                },
                {
                    key: 'is_deleted',
                    label: '是否已删除'
                },
                {
                    key: 'detail_info',
                    label: '详细信息'
                }
            ],
            addressFieldsAll: [
                {
                    key: 'address_name',
                    label: '名称'
                },
                {
                    key: 'user_name',
                    label: '收件人'
                },
                {
                    key: 'tel_number',
                    label: '电话号码'
                },
                {
                    key: 'province_name',
                    label: '省'
                },
                {
                    key: 'city_name',
                    label: '市'
                },{
                    key: 'district_name',
                    label: '地区'
                },
                {
                    key: 'is_deleted',
                    label: '是否已删除'
                },
                {
                    key: 'detail_info',
                    label: '详细信息'
                }
            ],
            loginLogFields: [],
            loginLogFieldsSimple: [
                {
                    key: 'avatar_url',
                    label: '头像'
                },
                {
                    key: 'name',
                    label: '姓名'
                },
                {
                    key: 'nick_name',
                    label: '昵称'
                },
                {
                    key: 'login_time',
                    label: '登录时间'
                }
            ],
            loginLogFieldsAll: [
                {
                    key: 'avatar_url',
                    label: '头像'
                },
                {
                    key: 'name',
                    label: '姓名'
                },
                {
                    key: 'nick_name',
                    label: '昵称'
                },
                {
                    key: 'customer_tel',
                    label: '手机'
                },
                // {
                //     key: 'last_login_time',
                //     label: '最后登录时间'
                // },
                {
                    key: 'login_time',
                    label: '登录时间'
                }
            ],
            selected: [],
            selectedCustomer: null,
            addresses: [],
            likes: [],
            feedbacks: [],
            loginLogs: [],
            selectedFeedback: null,
            feedbackPictures: [],
            modalShow: false,
            total: 0,
            currentPage: 1,
            pageSize: 20,
            totalLog: 0,
            currentLogPage: 1,
            logPageSize: 20
        }
    },
    mounted() {
        this.search();
        this.isMobile = this.getIsMobile();
        if(this.isMobile) {
            this.showAll = false;
            this.fields = this.fieldsSimple;
            this.feedbackFields = this.feedbackFieldsSimple;
            this.addressFields = this.addressFieldsSimple;
            this.loginLogFields = this.loginLogFieldsSimple;
        } else {
            this.showAll = true;
            this.fields = this.fieldsAll;
            this.feedbackFields = this.feedbackFieldsAll;
            this.addressFields = this.addressFieldsAll;
            this.loginLogFields = this.loginLogFieldsAll;
        }
        this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
            if(modalId == 'customer-info') {
                this.selectedFeedback = null;
            }
        });
    },
    watch: {
        currentPage: function(newValue, oldValue) {
            this.search();
        },
        currentLogPage: function(newValue, oldValue) {
            this.searchLoginLog();
        }
    },
    methods: {
        getGender: function(value) {
            return this.getGenderString(value);
        },
        getCategory(value) {
            return this.getCategoryString(value);
        },
        getStatusColor(is_replied) {
            return is_replied ? "#28a745" : "#007bff";
        },
        getIsDeletedColor(is_deleted) {
            return is_deleted ? "#F56C6C" : "#007bff";
        },
        dateFormat: function(time) {
            return this.dateFormatString(time);
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return;
            if (item.is_default === true && item.is_deleted == false) {
                return 'table-success';
            }
        },
        onSubmit() {
            this.search();
        },
        onReset() {
            this.form.search = '';
            this.pageSize = 20;
            this.currentPage = 1;
            this.search();
        },
        search(event) {
            if(event) {
                event.preventDefault();
            }
            Indicator.open({ text: '加载中...', spinnerType: 'fading-circle' });
            let params = this.getFilter();
            getCustomerList(params, response => {
                if(response.status == 200) {
                    this.customers = response.data.data;
                    this.total = response.data.total;
                }
                Indicator.close();
                this.selectedFeedback = null;
            });
        },
        getFilter() {
            return {
                "search": this.form.search == null ? '' : this.form.search,
                "page_size": this.pageSize,
                "current_page": this.currentPage
            };
        },
        onRowSelected(items) {
            this.selected = items;
            if(this.selected.length > 0) {
                this.selectedCustomer = this.selected[0];

                const query_address_data = {
                    customer_id: this.selectedCustomer.id
                };
                getCustomerAddressList(query_address_data, respAddresses => {
                    if(respAddresses.status == 200) {
                        this.addresses = respAddresses.data.data;
                    }
                });

                if(this.selectedCustomer.likes != "") {
                    const query_likes_data = {
						product_codes: this.selectedCustomer.likes
					};
                    getProductListByProductCodes(query_likes_data, respLikes => {
                        if(respLikes.status == 200) {
                            this.likes = respLikes.data.data;
                            if(this.likes.length > 0) {
                                this.likes.forEach(like => {
                                    if(like.picture_url) {
                                        like.picture_url = getPicturePath(like.picture_url);
                                    }
                                });
                            }
                        }
                    });
                } else {
                    this.likes = [];
                }
                
                const query_feedbacks_data = {
                    customer_id: this.selectedCustomer.id
                };
                getFeedbackList(query_feedbacks_data, respFeedbacks => {
                    if(respFeedbacks.status == 200) {
                        this.feedbacks = respFeedbacks.data.data;
                        this.selectedFeedback = null;
                    }
                });
                this.searchLoginLog();

                this.modalShow = true;
            }
        },
        searchLoginLog() {
            const query_login_logs_data = {
                "customer_id": parseInt(this.selectedCustomer.id),
                "page_size": this.logPageSize,
                "current_page": this.currentLogPage
            };
            getLoginLogList(query_login_logs_data, respLoginLogs => {
                if(respLoginLogs.status == 200) {
                    this.loginLogs = respLoginLogs.data.data;
                    this.totalLog = respLoginLogs.data.total;
                }
            });
        },
        clearLoginHistory() {
            let _this = this;
            MessageBox.confirm('确定清空登录历史?').then(action => {
                let query = {
                    "customer_id": parseInt(this.selectedCustomer.id)
                };
                deleteLoginLogList(query, resp => {
                    if(resp.status == 200) {
                        MessageBox('清空成功', '清空登录历史成功');
                        _this.loginLogs = [];
                        _this.totalLog = 0;
                        _this.$forceUpdate();
                    }
                });
            }).catch(()=>{});
        },
        onFeedbackRowSelected(items) {
            if(items.length > 0) {
                this.selectedFeedback = items[0];
                if(this.selectedFeedback.pictures_url != null && this.selectedFeedback.pictures_url != "") {
                    this.feedbackPictures = [];
                    let pictures = this.selectedFeedback.pictures_url.split(';');
                    if(pictures.length > 0) {
                        pictures.forEach(picture => {
                            this.feedbackPictures.push(getFeedbackPicturePath(picture));
                        });
                    }
                } else {
                    this.feedbackPictures = [];
                }
            } else {
                this.selectedFeedback = null;
            }
        }
    }
}
</script>

<style scoped>
.feedback-header {
    height:30px;
    line-height:30px;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
}

.like-header {
    height:30px;
    line-height:30px;
    margin-top:10px;
    margin-bottom:10px;
}

.login-header {
    height:30px;
    line-height:30px;
    margin-top:10px;
    margin-bottom:10px;
}

.likes {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-bottom:10px;
}

.like-item {
    cursor:pointer;
    margin-bottom:5px;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    margin-bottom: 10px;
}

.like-item-image {
    width:30%;
    height:auto;
}

.like-item-info {
    width: 70%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    margin-left: 10px;
}

.like-item-value {
    width: 100%;
    text-align: left;
    line-height: 150%;
    height: 28px;
}

.like-item-name {
    height: 52px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.feedback-item {
    height:28px;
    line-height:28px;
    margin-bottom:10px;
    margin-left:10px;
}

.feedback-image-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top;
    padding-bottom: 10px;
}

.feedback-image-item {
    width: 30%;
    height: auto;
    margin: 2.5px;
    padding: 5px;
    overflow: hidden;
    box-sizing: border-box;
}

#feedback-content {
    overflow-y: hidden !important;
    background-color: #FFF;
}

.buttons {
    width: 100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    margin-top: 10px;
}

.order-button {
    width: 30%;
}

.body {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    align-items:center;
}

.item {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    margin-bottom:10px;
}
</style>